<template>
  <div id="answer-key" class="my-5 test-answers">
    <b-container v-if="answers && sections && test" id="test-answer-key">
      <b-row id="answer-header" class="m-4 top-margin">
        <b-col class="text-center">
          <h2 id="answer-headline">
            {{ computeHeadline(test, answers) }}
          </h2>
          <p id="answer-text">
            Here are {{ test.is_admin ? answers.first_name + ' ' + answers.last_name: 'your' }} full test results. For each
            question answered incorrectly, you’ll find a link to the SolidProfessor video that contains the correct answer.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <b-tabs fill content-class="mt-3" no-nav-style
                    active-nav-item-class="active-tab" nav-class="answerkey">
              <!-- Render each 'Section' -->
              <div v-for="(section, index) in sections" :key="index">
                <b-tab :id="'section-name-' + index" :title="section.name">
                  <!-- Foreach Section, render all its questions -->
                  <div v-for="(question, questionIndex) in test.questions" :key="questionIndex">
                    <div v-if="section.id === question.section_id">
                      <b-row class="mt-5">
                        <b-col>
                          <h5 :id="'question-' + question.order">
                            Question {{ question.order }}
                          </h5>
                        </b-col>
                      </b-row>

                      <!-- Questions -->
                      <b-row v-if="question" :id="'question-text-' + question.order" align-v="center">
                        <b-col v-if="question.text">
                          <!-- Question text -->
                          <p :id="'question-content-' + question.order">
                            {{ question.text }}
                          </p>
                        </b-col>
                        <!-- Question image (if also has text) -->
                        <b-col v-if="question.image && question.text" class="text-right">
                          <b-img-lazy v-if="question.image" :id="'question-content-' + question.order"
                                      :src="SPENV.S3_URL + '/images/tests/' + question.image"
                                      fluid class="thumbnail" :alt="'Image for ' + question.section" />
                        </b-col>
                        <!-- Image only questions -->
                        <b-col v-if="question.image && !question.text" cols="12" class="m-4">
                          <b-img-lazy v-if="question.image" :id="'question-content-' + question.order"
                                      :src="SPENV.S3_URL + '/images/tests/' + question.image"
                                      fluid class="large-image" :alt="'Image for ' + question.section" />
                        </b-col>
                      </b-row>

                      <!-- Answers -->
                      <b-row :id="'answerkey-answer-' + question.order" class="mb-5">
                        <b-col>
                          <h5 class="mb-4">
                            Your answer
                          </h5>
                          <div v-if="getSelectedAnswer(question.question_id)">
                            <!-- Correct Answers -->
                            <div v-if="getSelectedAnswer(question.question_id)['correct_answer'] === getSelectedAnswer(question.question_id)['selected_answer']">
                              <!-- Just text -->
                              <p v-if="getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).text && !getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).image"
                                 :id="'answerkey-content-' + question.order" class="green">
                                <font-awesome-icon icon="check-circle" size="lg" /> {{ getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).text }}
                              </p>
                              <!-- Just image -->
                              <div v-if="getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).image && !getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).text"
                                   :id="'answerkey-content-' + question.order">
                                <font-awesome-icon icon="check-circle" size="lg" />
                                <b-img-lazy :src="SPENV.S3_URL + '/images/tests/' + getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).image"
                                            fluid class="small-image" />
                              </div>
                              <!-- Both -->
                              <p v-if="getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).text && getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).image"
                                 :id="'answerkey-content-' + question.order" class="green">
                                <font-awesome-icon icon="check-circle" size="lg" /> {{ getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).text }}
                                <b-img-lazy :src="SPENV.S3_URL + '/images/tests/' + getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).image"
                                            fluid class="small-image" />
                              </p>
                            </div>
                            <!-- Wrong Answers -->
                            <div v-else>
                              <!-- User Selected Answer Text -->
                              <div v-if="question.answers[getSelectedAnswer(question.question_id)['selected_answer']]">
                                <p v-if="question.answers[getSelectedAnswer(question.question_id)['selected_answer']].text"
                                   :id="'answerkey-content-' + question.order">
                                  <font-awesome-icon icon="times-circle" class="green" size="lg" />
                                  {{ question.answers[getSelectedAnswer(question.question_id)['selected_answer']].text }}
                                </p>
                                <!-- User Selected Answer Image -->
                                <div v-if="!getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).text && getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).image"
                                     :id="'answerkey-content-' + question.order">
                                  <font-awesome-icon icon="times-circle" class="green" size="lg" /> &nbsp;
                                  <b-img-lazy v-if="question.image"
                                              :src="SPENV.S3_URL + '/images/tests/' + getPossibleAnswer(questionIndex, getSelectedAnswer(question.question_id)['selected_answer']).image"
                                              fluid class="large-image" :alt="'Image for ' + question.section" />
                                </div>
                              </div>
                              <div v-else>
                                <p :id="'answerkey-content-' + question.order">
                                  <font-awesome-icon icon="times-circle" size="lg" />
                                  <i>  Answer not submitted</i>
                                </p>
                              </div>

                              <!-- Help box -->
                              <b-row class="answer-box m-1">
                                <b-col class="p-3">
                                  <p class="m-0">
                                    <!-- Correct Answer Location -->
                                    <font-awesome-icon icon="exclamation-circle" />
                                    &nbsp; You can find the answer to this question in the
                                    <b-link :to="{ name: 'course', params: { swslug:question.software_slug, courseslug:question.course_slug } }" class="blue">
                                      <b :id="'answerkey-helptext-' + question.order">{{ getSelectedAnswer(question.question_id)['answer_location']['section_name'] }}</b> section of the <b>{{ getSelectedAnswer(question.question_id)['answer_location']['course_name'] }} Course.</b>
                                    </b-link>
                                  </p>
                                </b-col>
                              </b-row>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <hr>
                    </div>
                  </div>
                </b-tab>
              </div>
            </b-tabs>
            <b-row class="mt-5 text-center">
              <b-col>
                <h3 class="mb-3">
                  Do you have any questions or feedback about the results?
                </h3>
                <b-link id="answer-key-contact-link" class="mb-5" @click="openExternal(SPENV.WWW_URL + '/contact-us/')">
                  Contact Us
                </b-link>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'AnswerKey',
  data() {
    return {};
  },
  computed: {
    answers () {
      return this.$store.getters['sptests/getAnswers'];
    },
    sections () {
      return this.$store.getters['sptests/getSections'];
    },
    test () {
      return this.$store.getters['sptests/getTest'];
    }
  },
  beforeCreate() {
    if (!this.$store.getters['sptests/getTest']) {
      this.$store.dispatch('sptests/getTest', this.$route.params['groupTestId']);
    }
    if (!this.$store.getters['sptests/getSections']) {
      this.$store.dispatch('sptests/getSections', this.$route.params['groupTestId']);
    }
    if (!this.$store.getters['sptests/getAnswers']) {
      this.$store.dispatch('sptests/getAnswers', {groupTestId: this.$route.params['groupTestId'], resultTestId: this.$route.params['groupResultId']});
    }
  },
  methods: {
    /**
     * Formats headline for answer key
     * @param {object} test --from API
     * @return {string}
     */
    computeHeadline(test, answers) {
      let returnString = 'Your results';
      if (test && test.is_admin && answers.first_name && answers.last_name) {
        returnString = answers.first_name + ' ' + answers.last_name + ' Results for ' + test.test_name;
      } else {
        returnString = 'Your Test Results for '+ test.test_name;
      }
      return returnString;
    },
    /**
     * Disambiguation for template. Gets an answer by index
     * @param index
     * @returns {object}
     */
    getSelectedAnswer(index) {
      if (this.answers) {
        return this.answers[index];
      }
    },
    /**
     * Disambiguation for the template. From the Test, for a question (indicated by index), gets an answer object
     * indicated by index2
     * @param index1 -- ordered question location in array
     * @param index2 -- key of the "answer choice"
     * @returns {*}
     */
    getPossibleAnswer(questionIndex, answerIndex) {
      return this.test.questions[questionIndex].answers[answerIndex];
    },
    /**
     * Open an external URL -- outside of the Vue project
     * @param extURL
     */
    openExternal(extURL) {
      window.open(extURL, '_blank');
    }
  },
};
</script>

<style lang="scss">
  .test-answers {
    :focus{
      outline-width: 0;
    }
    .answer-box {
      background: #D1ECF1;
      border: 1px solid #BEE5EB;
      box-sizing: border-box;
      border-radius: 4px;
      color: #00295B;
    }
    .blue, .blue:hover {
      color: #00295B;
    }
    .fa-check-circle {
      color: $sp_green;
    }
    .fa-times-circle {
      color: red;
    }
    h3 {
      text-transform: capitalize;
    }
    h5 {
      align-items: center;
      text-transform: capitalize;
    }
    .large-image {
      object-fit: cover;
      height: 150px;
    }
    .answerkey {
      .active-tab {
        color: $sp_green;
        border-bottom: 4px solid $sp_green;
      }
      .nav-item {
        color: grey;
        border-bottom: 1px solid $sp-gray-300;
      }
      .nav-link:hover {
        color: $sp_green;
      }
    }
    .small-image {
      height: 50px;
    }
    .thumbnail {
      object-fit: cover;
      height: 150px;
    }
    .top-margin {
      margin-top: 72px !important;
    }
  }
</style>
