<template>
  <div>
    <video id="videoPlayer" ref="videoPlayer"
           class="video-js" width="100%" />
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@/assets/scss/videojs-sp-skin.scss';


export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    videoUrl: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      player: null
    };
  },
  watch: {
    /**
     * Watches for change in videoURL param, and updates the player
     * @param newUrl -- string representing URL of playable video
     */
    videoUrl (newUrl) {
      this.player.src({type: 'video/mp4', src: newUrl});
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>
<style lang="scss">
</style>
