<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  watch: {
    'data': {
      handler (newOption) {
        if (this._chart) {
          this._chart.destroy();
          this.renderChart(this.chartData, this.options);
        }
      },
      deep: true
    }
  },
  mounted () {
    this.renderChart(this.data, this.options);
  }
};
</script>

<style scoped>

</style>
