<template>
  <div class="sp-test-results">
    <b-container>
      <b-row>
        <b-col class="text-center m-2 mt-5">
          <h3 class="custom-h3 m-2 large-top">
            Recommended Tutorials
          </h3>
          <p>Based on your test results, here are our top recommended video tutorials for you from the SolidProfessor course library.</p>
        </b-col>
      </b-row>
      <b-row class="my-5">
        <b-col cols="12" md="8">
          <VideoPlayer v-if="recommendedLessons" :options="videoOptions"
                       :video-url="videoUrl" class="my-2 mb-5 large-bottom" />
        </b-col>
        <b-col v-if="recommendedLessons" cols="12" md="4"
               class="padding-bottom">
          <b-row v-for="(lesson, index) in recommendedLessons" :key="index" no-gutters>
            <b-col v-if="index <= maxLessonDisplay" :id="'suggested-videos-' + index " class="pt-2 pointer"
                   @click="toggleCollapse(index), sendToPlayer(index)">
              <b-row align-v="center">
                <b-col cols="1">
                  <!-- Three icon states: locked, unlocked, unlocked and queued in player -->
                  <font-awesome-icon :icon="assignLessonIcon(index)"
                                     :class="videoUrl === recommendedLessons[index].url ? 'sp-green': null" />
                </b-col>
                <b-col>
                  <!-- Two states: selected (Now Playing) and not selected -->
                  <p v-if="videoUrl === recommendedLessons[index].url" class="lesson-name m-0 p-0 pointer">
                    {{ lesson.name }} (Now Playing)
                  </p>
                  <p v-else class="lesson-name m-0 p-0 pointer">
                    {{ lesson.name }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col offset="1">
                  <b-link v-if="lesson.course.url" :href="lesson.course.url" class="smaller-text">
                    From the course {{ lesson.course.name }}
                  </b-link>
                </b-col>
              </b-row>
              <b-collapse :id="'collapse-' + index" visible accordion="lesson-accordion">
                <b-row>
                  <b-col v-if="answers[lesson.source]" :id="'collapse-' + index" offset="1">
                    Recommended based on your answer to Question&nbsp;
                    {{ answers[lesson.source]['answer_location'].order }}
                  </b-col>
                </b-row>
              </b-collapse>
              <hr class="m-2">
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer';

export default {
  name: 'RecommendedVideos',
  components: { VideoPlayer },
  props: {},
  data() {
    return {
      maxLessonDisplay: 4,
      videoOptions: {
        autoPlay: false,
        aspectRatio: '16:9',
        controls: true,
        techOrder: ['html5'],
      },
      videoUrl: null,
    };
  },
  computed: {
    answers () {
      return this.$store.getters['sptests/getAnswers'];
    },
    recommendedLessons() {
      return this.$store.getters['sptests/getRecommendedLessons'];
    },
  },
  beforeCreate() {
    /**
     * Promise, dispatches to the store to load the appropriate "Recommended Lessons"
     * when the promise resolves (the recommended lesson data is returned), sets the active video to the
     * first lesson
     */
    this.$store.dispatch('sptests/getRecommendedLessons', this.$route.params['groupResultUuid']).then(() => {
      if (this.recommendedLessons) {
        this.videoUrl = this.recommendedLessons[0].url;
      }
    });
  },
  methods: {
    /**
     * Assigns a String representing a Fontawesome icon depending on lesson attributes
     * @param {integer} lessonIndex -- which recommendedLesson
     * @return {string} -- Fontawesome icon name, default is `lock`
     */
    assignLessonIcon(lessonIndex) {
      let returnIcon = 'lock';
      if (this.recommendedLessons && this.recommendedLessons[lessonIndex]) {
        if (this.videoUrl === this.recommendedLessons[lessonIndex].url) {
          returnIcon = 'play-circle';
        }
        if (this.user && this.user.membership && this.videoUrl !== this.recommendedLessons[lessonIndex].url) {
          returnIcon = 'lock-open';
        }
      }
      return returnIcon;
    },
    /**
     * If the user is logged in with a membership, allow full access. Otherwise, only show the first video
     * @param index -- which tab is clicked
     */
    sendToPlayer(index) {
      if (index === 0) {
        this.videoUrl = this.recommendedLessons[index].url;
      } else if (this.user && this.user.membership) {
        this.videoUrl = this.recommendedLessons[index].url;
      }
    },
    /**
     * Simple toggle emitter
     * @param index
     */
    toggleCollapse(index) {
      this.$root.$emit('bv::toggle::collapse', 'collapse-' + index);
    }
  },
};
</script>
<style lang="scss">
  .sp-test-results {
    .banner {
      background-color: #00295B;
    }
    custom-h3 {
      text-align: center;
      color: #414141;
    }
    .large-bottom {
      margin-bottom: 148px !important;
    }
    .large-top {
      margin-top: 110px !important;
    }
    .lesson-name {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      text-transform: capitalize;
      color: #222222;
    }
    /*.smaller-text {*/
    /*  font-size: 16px;*/
    /*}*/
  }
</style>
