<template>
  <b-container id="aggregate-score" class="aggregate-score">
    <b-row v-if="answers && sections" class="my-5 top-margin">
      <b-col>
        <h2 v-if="test" id="score-headline">
          {{ computeHeadline(test, answers, sections) }}
        </h2>
        <p id="score-text" class="my-4">
          The score of {{ getTotalScorePercentage(answers, sections) }}% reflects this participant’s overall proficiency and test performance. View the graph to see how they scored in each specific skill that the test evaluates. Scroll down for a detailed look at {{ test.is_admin ? 'the participant\'s': 'your' }} correct and incorrect answers plus a list of recommended courses to improve their skill set.
        </p>
        <p id="duration-text">
          <font-awesome-icon :icon="['far', 'clock']" class="purple" /><b> Total duration {{ computeDuration(answers.start_time, answers.end_time) }}</b>
        </p>
        <b-link v-scroll-to="{ el:'#test-answer-key', duration:1000}" class="my-3" href="#">
          See Your Answers
        </b-link>
      </b-col>
      <b-col class="constrain-height">
        <SPTestBarChart v-if="chartData" :data="chartData" :options="chartOptions" />
      </b-coL>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import SPTestBarChart from '@/components/Elements/BarChart';
import TimeToolsMixin from '@/utils/mixins/TimeTools';

export default {
  name: 'AggregateScore',
  components: { SPTestBarChart },
  mixins: [TimeToolsMixin],
  data() {
    return {
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display:false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 100,
              stepSize: 20,
            }
          }],
        }
      },
      groupTestId: null,
      resultTestId: null,
    };
  },
  computed: {
    answers() {
      return this.$store.getters['sptests/getAnswers'];
    },
    sections() {
      return this.$store.getters['sptests/getSections'];
    },
    test() {
      return this.$store.getters['sptests/getTest'];
    },
  },
  mounted() {
    if (!this.$store.getters['sptests/getTest']) {
      this.$store.dispatch('sptests/getTest', this.$route.params['groupTestId']);
    }
    if (!this.$store.getters['sptests/getAnswers']) {
      this.$store.dispatch('sptests/getAnswers', {groupTestId: this.$route.params['groupTestId'], resultTestId: this.$route.params['groupResultId']});
    }
    this.computeChartData();
  },
  created() {
    this.groupTestId = this.$route.params['groupTestId'];
    this.resultTestId = this.$route.params['groupResultUuid'];
  },

  methods: {
    /**
     * Counts the number of correct answers BY SECTION submitted by the user.
     * @param {array} answers -- associative array of answers from API
     * @return {array} -- associative array of correct answers { 'sectionName': correctCount, ..., }
     */
    computeAnswers(answers) {
      // list of keys to escape when checking answers
      let headsets = ['first_name', 'last_name', 'email', 'attempted', 'correct', 'start_time', 'end_time'];
      let correctAnswers = {};
      for (let item in answers) {
        // If the local key doesn't exist create it
        if (!headsets.includes(item)) {
          if (!correctAnswers[answers[item].answer_location.section_name]) {
            correctAnswers[answers[item].answer_location.section_name] = { correct: 0 };
          }
          // Matching answers indicate a correct answer, increment count
          if (answers[item].correct_answer === answers[item].selected_answer) {
            correctAnswers[answers[item].answer_location.section_name].correct++;
          }
        }
      }
      return correctAnswers;
    },
    /**
     * Compares the number of correct vs max correct answers, converts them to a percentage, and pushes them to an
     * ordered array (ordered by section)
     * @param {array} answers -- associative array of test answers from API
     * @param {array} sections -- array of sections from API
     * @return {array} -- each item is percentage correct correlative score for that section
     */
    getCorrectAnswers(answers, sections) {
      let returnAnswers = [];
      let correctAnswers = this.computeAnswers(answers);
      let maxCorrect = this.getMaxSectionHeight(sections);
      for (let section in sections) {
        if (correctAnswers[sections[section].name]) {
          let asPercentage = parseInt((correctAnswers[sections[section].name]['correct'] / maxCorrect) * 100);
          returnAnswers.push(asPercentage);
        }
      }
      return returnAnswers;
    },
    /**
     * Wrapper to initiate process to compute all data for bar graph
     *
     * In order to manage a race condition, if `this.answers` or `this.sections` is not set, establish a promise to get
     * both before calling next function.
     *
     * If both are set, just call next function
     * @return {void}  -- calls another function
     *
     */
    computeChartData() {
      if (!this.answers || !this.sections) {
        // Promise 1
        let answerPromise = new Promise((resolve, reject) => {
          let response = this.$store.dispatch('sptests/getAnswers', {groupTestId: this.groupTestId, resultTestId: this.resultTestId});
          resolve(response);
        });
        // Promise 2
        let sectionPromise = new Promise((resolve, reject) => {
          let response = this.$store.dispatch('sptests/getSections', this.groupTestId);
          resolve(response);
        });
        // BOTH Promises are required to resolve
        Promise.all([answerPromise, sectionPromise]).then(() => {
          this.setChartData(this.answers, this.sections);
        });
      } else {
        this.setChartData(this.answers, this.sections);
      }
    },
    /**
     * Uses `test.company_name` to determine if the user is part of a group and then tailors the headline
     *
     * @param {object} test -- a valid test from API
     * @param {object} answers -- test answers from API
     * @param {object} sections -- test sections from API
     *
     * @return {string}
     */
    computeHeadline(test, answers, sections) {
      let returnString = 'Your results';
      if (test && test.is_admin && answers.first_name && answers.last_name) {
        returnString = answers.first_name + ' ' + answers.last_name + ' scored ' + this.getTotalScorePercentage(answers, sections) + '%';
      } else {
        returnString = 'You Scored ' + this.getTotalScorePercentage(answers, sections) + '%';
      }
      return returnString;
    },
    /**
     * Iterates through `sections` object from API and extracts names
     * @param APISections -- `section` object from API
     * @returns {array} -- ordered array of section String names
     */
    getSectionNames(APISections) {
      let sections = [];
      if (APISections) {
        for (let section in APISections) {
          sections.push(APISections[section].name);
        }
      }
      return sections;
    },
    /**
     * Finds the section with the most questions (to set Y height on bar graph)
     * @param {array} APISections -- `section` object from API
     * @returns {number} -- largest number
     */
    getMaxSectionHeight(APISections) {
      return Math.max(...this.getQuestionsPerSection(APISections));
    },
    /**
     * Gets a total number of questions per section from API data
     * @param {array} APISections -- `sections` from API
     * @return {array} -- order array of section question totals (same order as API data)
     */
    getQuestionsPerSection(APISections) {
      let sections = [];
      if (APISections) {
        for (let section in APISections) {
          sections.push(APISections[section].question_count);
        }
      }
      return sections;
    },
    /**
     * Gets the percent correct per section, adds them all together, then divides by the total possible
     * NOTE: Assumes Percentages, and that each `Section` is weighed as 100%
     * @param {array} answers -- associative array from API
     * @param {array} sections -- associative array from API
     *
     * NOTE: `arraySum` is an invokable function
     *
     * return {Integer} -- percentage correct, totalled, 0-100
     */
    getTotalScorePercentage(answers, sections){
      const totalArray = this.getCorrectAnswers(answers, sections);
      const arraySum = totalArray => totalArray.reduce((a, b) => a + b, 0);
      return arraySum(totalArray) / totalArray.length;
    },
    /**
     * Bar chart data is responsive. Builds object from API results.
     * @return {void}
     *
     * NOTE: uses Vue.set() to set an attribute that serves as a `param` for the bar chart
     * Vue.set() is required for nested objects
     */
    setChartData(answers, sections) {
      let localData = {
        labels: this.getSectionNames(sections),
        datasets: [
          {
            label: '% Correct',
            backgroundColor: '#B1D19E',
            data: this.getCorrectAnswers(answers, sections)
          }
        ],
      };
      Vue.set(this, 'chartData', localData);
    },
  }

};
</script>

<style lang="scss">
  .aggregate-score {
    .constrain-height {
      height: 35vh;
    }
    h2 {
      align-items: center;
      color: #414141;
    }
    .top-margin {
      margin-top: 72px !important;
    }
  }

</style>
