<template>
  <div>
    <b-container v-if="test && sections && answers" id="test-results">
      <div>
        <b-alert id="email-alert" show dismissible
                 class="my-3">
          <b>How To Access Your Results Again:</b>
          &nbsp; A link to your Results has been sent to {{ answers.email || 'your email' }}
        </b-alert>
      </div>
      <b-row>
        <b-col>
          <AggregateScore />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <RecommendedVideos />
        </b-col>
      </b-row>
    </b-container>
    <Banner v-if="test && sections && answers" :banner-data="bannerData" />
    <b-container v-if="test && sections && answers">
      <b-row>
        <b-col>
          <AnswerKey />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AggregateScore from '@/components/SPTests/SPTestAggregateScore';
import AnswerKey from '@/components/SPTests/SPTestAnswerKey';
import Banner from '@/components/Banner';
import RecommendedVideos from '@/components/Skillsgap/SkillsgapRecommendedVideos';

export default {
  name: 'SPTestResults',
  components: { AggregateScore, AnswerKey, Banner, RecommendedVideos },
  data() {
    return {
      'bannerData': {
        text: 'Want to access all 5,000+ video tutorials? Discover hundreds of courses in CAD, CAM, BIM, engineering methods, and more when you become a SolidProfessor member.',
        button: {
          text: 'Explore Library',
          href:  {
            url: 'library',
            named: true
          },
        },
      },
    };
  },
  computed: {
    answers () {
      return this.$store.getters['sptests/getAnswers'];
    },
    sections () {
      return this.$store.getters['sptests/getSections'];
    },
    test () {
      return this.$store.getters['sptests/getTest'];
    }
  },
  created() {
    if (!this.$store.getters['sptests/getAnswers']) {
      this.$store.dispatch('sptests/getAnswers', {groupTestUuid: this.$route.params['groupTestId'], resultTestUuid: this.$route.params['groupResultUuid']});
    }
    if (!this.$store.getters['sptests/getSections']) {
      this.$store.dispatch('sptests/getSections', this.$route.params['groupTestId']);
    }
    if (!this.$store.getters['sptests/getTest']) {
      this.$store.dispatch('sptests/getTest', this.$route.params['groupTestId']);
    }
  }
};
</script>

<style lang="scss">

</style>
